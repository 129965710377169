import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M1803 9381 c-64 -23 -127 -80 -159 -143 l-24 -47 0 -1521 c0 -1680
-5 -1562 66 -1616 l37 -29 902 0 901 0 37 25 c71 48 67 -8 67 1075 0 650 3
975 10 975 12 0 130 -88 215 -161 33 -29 80 -68 104 -88 24 -20 60 -50 80 -67
20 -17 59 -51 87 -75 27 -24 81 -71 118 -104 38 -33 89 -75 113 -95 172 -136
341 -288 360 -323 23 -45 12 -107 -33 -180 -56 -91 -197 -308 -271 -417 -124
-182 -473 -710 -473 -715 0 -10 645 -5 683 6 43 12 101 59 122 99 13 25 41
180 80 440 57 383 60 394 117 414 29 10 92 1 110 -17 7 -7 49 -41 93 -76 44
-34 89 -70 100 -79 11 -10 65 -53 119 -97 194 -157 284 -231 377 -310 52 -44
105 -89 118 -100 133 -109 181 -158 190 -193 19 -69 -20 -151 -85 -178 -28
-12 -211 -14 -1063 -14 -706 0 -1039 3 -1058 11 -15 6 -64 34 -108 62 l-80 52
-1017 3 -1018 2 0 -55 0 -55 990 0 c1102 0 1003 6 1105 -69 28 -21 66 -43 85
-50 27 -9 272 -12 1080 -11 575 1 1064 5 1087 9 115 22 207 161 189 288 -10
75 -46 122 -173 228 -65 54 -125 105 -133 114 -8 9 -49 42 -90 73 -41 32 -89
70 -106 85 -17 16 -71 60 -120 100 -49 40 -159 129 -244 198 -163 133 -223
170 -287 181 -81 13 -166 -32 -208 -108 -25 -46 -34 -93 -80 -433 -49 -356
-59 -396 -111 -415 -31 -12 -474 -13 -474 -2 0 8 27 49 149 227 121 176 389
582 456 690 37 61 77 134 87 164 37 105 2 196 -114 291 -34 29 -85 71 -113 95
-50 42 -317 271 -365 312 -14 12 -59 50 -100 84 -41 34 -77 65 -80 68 -3 3
-32 29 -65 56 -103 86 -107 90 -119 101 -6 5 -67 57 -136 114 -69 57 -127 107
-130 110 -5 5 -184 158 -229 195 -13 11 -61 52 -106 90 -45 39 -117 99 -160
134 -42 36 -82 69 -89 75 -6 6 -38 33 -71 61 -33 27 -62 52 -65 55 -3 3 -75
64 -160 135 -85 71 -157 131 -160 134 -3 3 -25 22 -50 42 -25 20 -58 49 -75
63 -49 44 -157 104 -205 115 -25 6 -119 10 -210 10 -136 0 -174 -3 -217 -18z"
          />
          <path
            d="M6905 6203 c-35 -9 -72 -41 -89 -77 -15 -32 -16 -111 -14 -853 3
-809 3 -818 24 -863 25 -53 82 -111 133 -137 33 -17 76 -18 531 -21 337 -2
509 1 540 8 104 26 170 104 171 203 1 85 -10 105 -197 363 -32 45 -110 153
-173 240 -118 164 -237 327 -397 544 -107 145 -247 337 -336 462 -60 83 -124
139 -158 137 -8 -1 -24 -4 -35 -6z"
          />
          <path
            d="M1620 5103 l0 -546 23 20 c12 11 60 45 107 76 47 30 172 112 278 181
106 69 196 126 201 126 5 0 11 4 13 8 2 5 35 29 73 54 326 211 484 313 565
363 52 33 97 63 98 67 2 4 8 8 13 8 5 0 42 22 82 49 69 46 148 95 188 116 10
5 19 13 19 17 0 5 -373 8 -830 8 l-830 0 0 -547z"
          />
          <path
            d="M3433 5581 c-34 -16 -75 -38 -90 -49 -15 -11 -86 -56 -158 -101 -123
-78 -586 -376 -850 -548 -66 -43 -245 -159 -397 -258 -152 -99 -291 -188 -308
-197 l-31 -17 21 -42 c26 -51 63 -84 115 -104 36 -14 193 -15 1440 -13 1392 3
1400 3 1435 24 19 11 45 35 57 54 44 65 27 118 -78 240 -40 47 -75 87 -79 90
-3 3 -62 70 -130 150 -69 80 -130 151 -137 158 -8 8 -66 75 -130 150 -65 76
-124 144 -133 152 -8 8 -35 38 -59 65 -99 113 -218 228 -256 247 -24 13 -66
23 -105 25 -57 4 -73 0 -127 -26z"
          />
          <path
            d="M7945 3309 c-116 -17 -176 -48 -244 -125 -67 -76 -86 -153 -86 -344
0 -176 13 -235 68 -316 36 -53 107 -107 165 -125 20 -6 93 -13 162 -16 129 -5
206 6 293 44 l37 16 -2 231 -3 231 -167 3 -168 2 0 -80 0 -80 53 -2 c28 0 59
-1 67 -2 12 -1 16 -19 18 -87 l3 -86 -38 -7 c-58 -9 -139 -7 -170 5 -82 31
-113 106 -113 273 0 125 11 176 46 223 48 64 189 79 357 39 l58 -14 13 27 c25
48 48 133 40 141 -19 16 -113 40 -199 50 -101 11 -106 11 -190 -1z"
          />
          <path
            d="M1845 3290 c-72 -23 -128 -67 -157 -123 -26 -49 -31 -169 -10 -228
26 -73 110 -133 233 -164 127 -32 187 -56 203 -80 25 -38 20 -82 -11 -112 -28
-25 -30 -25 -148 -20 -88 3 -136 10 -179 26 -33 11 -67 21 -76 21 -18 0 -44
-67 -56 -140 -6 -36 -5 -38 28 -49 123 -41 345 -54 451 -26 142 37 202 118
201 270 0 86 -19 139 -66 182 -34 32 -140 77 -228 98 -102 23 -159 51 -166 81
-9 37 3 69 32 84 35 19 177 19 272 1 46 -9 76 -11 82 -5 13 13 55 153 48 160
-19 19 -130 36 -253 40 -120 4 -149 1 -200 -16z"
          />
          <path
            d="M5130 3296 c-80 -24 -99 -35 -154 -88 -66 -65 -92 -131 -106 -265
-11 -108 1 -285 24 -351 37 -103 109 -169 213 -196 118 -31 361 -14 451 31
l35 18 -2 224 c-1 124 -4 228 -7 233 -3 4 -77 8 -164 8 l-159 0 -7 -35 c-3
-19 -3 -55 0 -80 l7 -45 65 0 64 0 0 -89 0 -88 -37 -7 c-129 -21 -221 7 -257
79 -17 33 -22 63 -25 174 -4 154 7 207 53 256 23 25 46 36 91 45 59 13 101 10
296 -17 l35 -5 23 77 c13 47 19 81 13 87 -37 37 -361 62 -452 34z"
          />
          <path
            d="M8682 3291 c-179 -67 -242 -185 -241 -456 0 -231 65 -360 215 -427
87 -39 285 -37 426 4 20 6 39 16 41 22 5 12 -27 153 -36 163 -4 3 -39 -1 -79
-10 -115 -25 -165 -29 -223 -17 -111 23 -140 89 -134 305 4 145 17 181 77 221
54 37 116 38 308 8 l41 -6 22 63 c32 97 30 105 -31 125 -36 11 -99 18 -193 21
-120 3 -147 1 -193 -16z"
          />
          <path
            d="M2430 2845 l0 -455 100 0 100 0 0 198 c1 188 1 196 18 176 9 -12 71
-101 136 -198 l119 -176 114 0 c72 0 113 4 113 10 0 6 -21 39 -47 74 -55 74
-56 75 -163 225 -114 161 -110 141 -51 224 28 40 92 136 143 214 51 78 95 143
99 143 3 0 68 -123 145 -272 l139 -273 5 -170 5 -170 100 0 100 0 5 170 5 170
142 278 143 277 -100 0 -100 0 -73 -152 c-40 -84 -83 -173 -94 -198 l-21 -45
-22 50 c-12 28 -53 116 -92 196 l-70 146 -96 6 c-182 11 -316 9 -333 -5 -9 -7
-45 -58 -79 -113 -35 -55 -91 -140 -124 -190 l-61 -90 -3 70 c-2 39 -2 127 0
197 2 99 0 129 -10 133 -8 3 -54 5 -103 5 l-89 0 0 -455z"
          />
          <path
            d="M3934 3285 c-2 -5 -3 -208 -2 -450 l3 -440 280 0 280 0 3 79 c2 57
-1 82 -10 87 -7 5 -88 9 -180 9 l-168 0 -2 361 -3 361 -99 1 c-58 1 -100 -2
-102 -8z"
          />
          <path d="M4552 2843 l3 -448 98 -3 97 -3 0 451 0 450 -100 0 -100 0 2 -447z" />
          <path
            d="M5722 2843 l3 -448 100 0 100 0 0 180 c0 108 4 183 10 187 6 4 76 8
158 8 l147 0 0 -190 0 -191 103 3 102 3 3 448 2 447 -105 0 -105 0 0 -185 0
-186 -156 3 -156 3 -2 180 -1 180 -103 3 -102 3 2 -448z"
          />
          <path
            d="M6512 3203 l3 -88 123 -3 122 -3 0 -360 0 -360 103 3 102 3 2 355 1
355 116 3 116 3 0 89 0 90 -345 0 -346 0 3 -87z"
          />
          <path
            d="M2210 2053 c0 -76 4 -144 8 -151 12 -17 64 -20 69 -4 3 8 -4 16 -16
19 -20 5 -21 12 -21 139 0 127 -1 134 -20 134 -19 0 -20 -7 -20 -137z"
          />
          <path
            d="M5572 2048 c-28 -79 -52 -149 -52 -155 0 -25 28 -12 49 22 20 34 22
35 81 35 60 0 61 0 76 -35 10 -24 21 -35 35 -35 16 0 19 4 14 18 -4 9 -17 51
-31 92 -46 143 -71 195 -96 198 -21 3 -27 -9 -76 -140z m104 20 c9 -29 19 -59
22 -65 3 -9 -10 -13 -48 -13 -38 0 -51 4 -48 13 3 6 13 36 22 65 10 28 21 52
26 52 5 0 16 -24 26 -52z"
          />
          <path
            d="M1730 2150 c0 -17 7 -20 50 -20 l50 0 0 -120 0 -120 25 0 24 0 3 123
c2 88 6 122 16 123 6 1 26 2 42 3 19 1 30 6 30 16 0 13 -20 15 -120 15 -113 0
-120 -1 -120 -20z"
          />
          <path
            d="M4093 2154 c-3 -8 0 -21 6 -27 10 -9 16 -8 26 7 8 10 12 23 9 27 -9
15 -34 10 -41 -7z"
          />
          <path
            d="M4724 2155 c-8 -19 12 -36 33 -28 19 7 12 37 -10 41 -9 2 -20 -4 -23
-13z"
          />
          <path
            d="M4600 2124 c0 -17 -6 -28 -20 -31 -11 -3 -20 -11 -20 -18 0 -7 9 -15
20 -18 18 -4 20 -14 20 -75 0 -77 18 -106 62 -100 33 5 37 38 4 38 -25 0 -26
2 -26 64 0 59 2 65 23 68 12 2 22 10 22 18 0 8 -10 16 -22 18 -18 3 -23 10
-23 33 0 22 -5 29 -20 29 -15 0 -20 -7 -20 -26z"
          />
          <path
            d="M2022 2083 c-22 -9 -44 -66 -44 -112 1 -59 34 -91 92 -91 50 0 90 15
90 35 0 13 -10 14 -56 9 -49 -6 -59 -4 -70 11 -22 30 -17 35 39 35 28 0 63 3
76 7 20 5 22 10 17 42 -7 48 -37 71 -90 70 -22 0 -47 -3 -54 -6z m90 -40 c29
-26 21 -33 -37 -33 -57 0 -66 8 -39 34 20 21 53 20 76 -1z"
          />
          <path
            d="M2337 2070 c-50 -40 -45 -154 8 -178 52 -24 135 -9 135 23 0 11 -12
12 -56 8 -48 -5 -59 -3 -70 12 -23 31 -17 35 44 35 74 0 97 15 87 58 -3 18
-16 39 -28 47 -31 21 -89 19 -120 -5z m97 -26 c27 -26 18 -34 -39 -34 -59 0
-64 4 -38 32 20 22 56 23 77 2z"
          />
          <path
            d="M2545 2065 c-21 -20 -25 -34 -25 -80 0 -46 4 -60 25 -80 34 -35 125
-34 125 1 0 10 -13 14 -43 14 -54 0 -67 13 -67 67 0 34 5 46 25 59 16 10 34
14 50 10 33 -9 50 1 34 20 -7 8 -30 14 -56 14 -33 0 -50 -6 -68 -25z"
          />
          <path
            d="M2745 2078 c-35 -20 -44 -41 -45 -100 0 -49 3 -59 26 -77 41 -32 98
-28 135 8 24 25 29 37 29 81 0 41 -5 56 -25 75 -27 28 -84 33 -120 13z m83
-34 c25 -18 30 -77 8 -106 -15 -21 -66 -24 -84 -6 -20 20 -15 85 8 108 24 24
38 25 68 4z"
          />
          <path
            d="M2925 2080 c-10 -16 -4 -174 7 -187 18 -22 28 -3 28 52 0 91 30 132
79 105 17 -9 21 -22 23 -88 2 -60 6 -77 18 -77 12 0 16 17 20 75 5 68 7 76 32
89 24 14 28 14 47 -3 16 -15 21 -33 23 -90 2 -55 6 -71 18 -71 12 0 16 16 18
69 5 112 -22 148 -101 132 -39 -8 -52 -8 -98 0 -20 3 -46 1 -57 -5 -14 -7 -23
-8 -27 -1 -8 13 -22 13 -30 0z"
          />
          <path
            d="M3280 1991 c0 -76 3 -100 14 -104 22 -9 26 2 26 64 0 31 3 65 6 73
10 26 39 37 68 26 25 -9 26 -13 26 -84 0 -54 4 -75 14 -79 22 -9 26 2 26 70 0
50 4 67 20 83 11 11 25 20 30 20 6 0 19 -9 30 -20 16 -16 20 -33 20 -85 0 -58
2 -65 20 -65 18 0 20 7 20 70 0 110 -25 141 -103 126 -39 -8 -52 -8 -98 0 -20
3 -46 1 -57 -5 -14 -7 -23 -8 -27 -1 -3 5 -13 10 -21 10 -11 0 -14 -19 -14
-99z"
          />
          <path
            d="M3640 2001 c0 -82 2 -91 22 -105 25 -18 65 -21 87 -7 9 6 22 6 32 1
28 -16 37 10 35 105 -1 77 -4 90 -18 93 -16 3 -18 -5 -18 -66 0 -79 -18 -106
-67 -100 -28 3 -28 3 -31 86 -3 74 -5 82 -22 82 -18 0 -20 -7 -20 -89z"
          />
          <path
            d="M3863 2074 c-2 -6 -3 -51 -1 -100 2 -71 6 -89 18 -89 11 0 16 17 20
72 3 40 10 78 17 84 13 13 69 17 80 5 4 -4 9 -37 11 -74 4 -83 7 -93 27 -86
11 5 15 22 15 69 0 73 -15 122 -39 130 -29 9 -143 1 -148 -11z"
          />
          <path
            d="M4097 2084 c-4 -4 -7 -49 -7 -101 0 -87 1 -93 20 -93 20 0 21 5 18
97 -3 89 -12 116 -31 97z"
          />
          <path
            d="M4200 2068 c-31 -34 -32 -130 -1 -162 23 -23 105 -34 126 -16 23 19
8 30 -39 30 -54 0 -66 13 -66 71 0 52 8 59 69 59 42 0 52 6 44 26 -3 10 -22
14 -59 14 -42 0 -58 -5 -74 -22z"
          />
          <path
            d="M4387 2083 c-4 -3 -7 -12 -7 -20 0 -10 10 -12 40 -7 43 7 70 -6 70
-35 0 -13 -9 -15 -44 -13 -35 3 -49 -1 -65 -17 -49 -49 -8 -121 61 -107 35 7
51 8 82 4 17 -3 19 3 17 66 -3 72 -14 115 -34 128 -15 9 -111 10 -120 1z m101
-130 c-3 -25 -7 -28 -45 -31 -40 -3 -43 -1 -43 22 0 28 11 34 58 35 31 1 33
-1 30 -26z"
          />
          <path
            d="M4733 2083 c-9 -3 -13 -32 -13 -99 0 -94 0 -94 25 -94 25 0 25 0 25
94 0 94 -6 110 -37 99z"
          />
          <path
            d="M4839 2071 c-19 -15 -24 -30 -27 -83 -4 -62 -3 -66 24 -87 23 -18 38
-22 73 -18 58 6 83 33 89 96 4 46 2 53 -26 81 -37 36 -96 41 -133 11z m107
-38 c7 -10 13 -31 13 -48 0 -16 -6 -38 -13 -47 -17 -23 -81 -24 -90 -2 -10 27
-7 89 6 102 18 18 69 15 84 -5z"
          />
          <path
            d="M5037 2083 c-4 -3 -7 -48 -7 -100 0 -93 0 -93 25 -93 24 0 25 2 25
69 0 61 3 72 22 85 53 37 78 8 78 -90 0 -67 2 -75 18 -72 14 3 17 16 20 85 2
72 0 85 -18 103 -22 22 -69 26 -103 9 -16 -9 -22 -9 -25 0 -4 12 -25 15 -35 4z"
          />
          <path
            d="M5276 2074 c-40 -39 -5 -104 55 -104 15 0 32 -7 39 -15 21 -25 1 -37
-58 -33 -46 2 -57 0 -60 -13 -8 -42 132 -37 160 5 27 39 -6 79 -74 91 -27 5
-38 12 -38 25 0 24 23 31 71 24 31 -5 39 -3 39 9 0 19 -21 27 -75 27 -26 0
-50 -6 -59 -16z"
          />
          <path
            d="M5813 2074 c-2 -5 -2 -71 -1 -145 3 -126 4 -134 23 -134 17 0 20 6
17 43 l-4 42 52 0 c73 0 100 28 100 105 0 46 -4 60 -25 81 -22 22 -32 24 -91
22 -37 -2 -68 -8 -71 -14z m133 -39 c12 -18 15 -38 11 -66 -6 -48 -5 -47 -44
-55 -40 -8 -63 16 -63 65 0 39 14 65 40 74 29 10 40 7 56 -18z"
          />
          <path
            d="M6040 2080 c-3 -29 2 -285 6 -291 2 -5 11 -6 19 -2 10 3 15 20 15 52
l0 46 43 -3 c35 -3 50 1 73 21 26 23 29 31 29 87 0 84 -19 100 -116 97 -38 -1
-69 -4 -69 -7z m141 -57 c16 -41 -4 -99 -36 -107 -45 -11 -65 9 -65 62 0 57
12 72 57 72 27 0 35 -5 44 -27z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
